import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class AdminAndCashierReportProvider extends HttpRequest {
  getAdminSalesReport (query) {
    this.setHeader(getAuthToken())

    return this.get('/report/admin-sales', query)
  }

  getOfflineConversionsReport (query) {
    this.setHeader(getAuthToken())

    return this.get('/report/offline-conversions', query)
  }

  getTelAndLineCountWarehouseReport (query) {
    this.setHeader(getAuthToken())

    return this.get('/report/tel-and-line-count-warehouses', query)
  }

  getTelAndLineCountCashierReport (query) {
    this.setHeader(getAuthToken())

    return this.get('/report/tel-and-line-count-cashiers', query)
  }
}

export default AdminAndCashierReportProvider
